<template>
  <div>
    <h4>Standings for {{ event.title }}</h4>
    <p>
      Please use the file selector below to select the image file (.png/.jpg) containing the event's standings.
    </p>
    <b-file name="file" accept="application/pdf, image/png, image/jpeg" v-model="location" placeholder="File" />
    <b-btn class="mt-4" block @click.prevent="onPostMedia">Upload</b-btn>
    <div v-if="event.standings" class="my-4">
    <b-img :src="'//api.nzonesports.com/media/' + event.standings.contentUrl" fluid alt=""></b-img><br />
    <b-btn variant="link" @click="deleteStandings()">Delete Standings</b-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const IRI_MEDIA = '/api/media_objects/'
export default {
  data () {
    return {
      eid: null,
      event: {},
      location: {}
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'currentUser'
    ])
  },
  methods: {
    ...mapActions([
      'getEvent',
      'putEvent',
      'postMedia',
      'deleteMedia'
    ]),
    checkFile () {
      if (!this.location.name) {
        alert('please select a file')
        return false
      }
      return true
    },
    async onPostMedia () {
      if (!this.checkFile()) return
      let mediaResp = await this.postMedia({
        user: this.currentUser.id,
        slug: this.business.uriName + '/standings',
        business: this.business.id,
        file: this.location
      })
      this.putEvent({
        id: this.eid,
        standings: IRI_MEDIA + mediaResp.id
      }).then(resp => {
        this.event = resp
      })
    },
    deleteStandings () {
      this.$bvModal.msgBoxConfirm('Delete Standings?')
      .then(confirm => {
        if (confirm) {
          let medid = this.event.standings.id
          this.putEvent({
            id: this.eid,
            standings: null
          }).then(resp => {
            this.event = resp
            this.deleteMedia(medid)
          })
        }
      })
    },
    doGetEvent () {
      this.eid = this.$route.params.eid
      if (this.eid) {
        this.getEvent({
          id: this.eid
        }).then(resp => {
          this.event = resp
        })
      }
    }
  },
  created () {
    this.doGetEvent()
  }
}
</script>